import { useMutation } from '@apollo/client';
import { Favorite, FilterList, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Chip,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { AutocompleteElement, FormContainer } from 'react-hook-form-mui';
import { FrequencyFilters, InsightType } from '../../__generated__/graphql';
import { GET_CURRENT_USER, GET_INSIGHTS } from '../../graphql/queries';
import TopicSelectorModal from './TopicSelectorModal';
import { UPDATE_BRAND_INSIGHTS_TOPICS } from '../../graphql/mutations';
import { AuthContext } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';

export interface InsightsFiltersData {
  topics: string[];
  favorites: boolean;
  frequency: FrequencyFilters;
  insightTypes: InsightType[];
}

export const InsightsFilters = ({
  filters,
  onFiltersChange,
  dateFilters,
}: {
  filters: InsightsFiltersData;
  onFiltersChange: (filters: InsightsFiltersData) => void;
  dateFilters: boolean;
}) => {
  const { t } = useTranslation();
  const [anchorModalElem, setAnchorModalElem] = useState<HTMLButtonElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [topicModalOpen, setTopicModalOpen] = useState<boolean>(false);
  const { user } = useContext(AuthContext);

  const [updateUserInsights] = useMutation(UPDATE_BRAND_INSIGHTS_TOPICS, {
    refetchQueries: [GET_CURRENT_USER],
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateTopics = (topics: string[]) => {
    const uniqueTopics = Array.from(new Set(topics));

    onFiltersChange({
      ...filters,
      topics: uniqueTopics,
    });

    updateUserInsights({
      variables: {
        brandId: user?.brandSelectedId!,
        topics: uniqueTopics,
      },
      awaitRefetchQueries: true,
      refetchQueries: [GET_INSIGHTS],
    });
  };

  const toggleFilterOption = (filterClicked: InsightType) => {
    const isOptionAll = filterClicked === InsightType.All;
    const includesAll = filters.insightTypes.includes(InsightType.All);
    const includesOption = filters.insightTypes.includes(filterClicked);

    const newInsightType =
      isOptionAll || includesAll || !includesOption
        ? [filterClicked]
        : filters.insightTypes.filter((o) => o !== filterClicked);

    onFiltersChange({ ...filters, insightTypes: newInsightType });
  };

  const handleTopicModalClick = (event: any) => {
    setAnchorModalElem(event.currentTarget);

    if (filters.favorites) {
      return;
    }

    setTopicModalOpen(true);
  };

  return (
    <Card
      sx={{
        padding: '17px 25px',
        width: '100%',
        borderRadius: '8px',
        marginBottom: '1rem',
      }}
    >
      <Stack
        direction={{ sm: 'column', md: 'row' }}
        spacing={1}
        justifyContent={'end'}
        width={'100%'}
      >
        <Box display="flex" flexWrap="wrap" gap={1}>
          {!filters.favorites && (
            <>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClick}
                data-testid="new-advocacy-post-button"
                sx={{
                  marginLeft: '5px',
                  backgroundColor: !filters.insightTypes.includes(InsightType.All)
                    ? '#FFD8EB'
                    : '#FFF',
                  border: !filters.insightTypes.includes(InsightType.All)
                    ? '1px solid #FF007A'
                    : '1px solid #BBBBBB',
                  color: !filters.insightTypes.includes(InsightType.All)
                    ? '#FF007A'
                    : 'rgba(0, 0, 0, 0.56)',
                }}
              >
                <FilterList />
              </Button>

              <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                {[
                  t(FrequencyFilters.Day),
                  t(FrequencyFilters.Week),
                  t(FrequencyFilters.Month),
                ].map((period) => (
                  <Button
                    key={period}
                    onClick={() =>
                      onFiltersChange({
                        ...filters,
                        frequency: period as FrequencyFilters,
                      })
                    }
                    variant={filters.frequency === period ? 'contained' : 'outlined'}
                  >
                    {t('Last')} {period}
                  </Button>
                ))}
              </ButtonGroup>

              <FormContainer values={{ topics: filters.topics }}>
                <AutocompleteElement
                  name="topics"
                  data-testid="topics-input"
                  multiple
                  options={[]}
                  textFieldProps={{
                    variant: 'outlined',
                    onClick: handleTopicModalClick,
                    placeholder:
                      filters.topics.length === 0 || topicModalOpen
                        ? t('Search new topic')
                        : '',
                    disabled: filters.favorites,
                    InputLabelProps: {
                      shrink: true,
                      disabled: true,
                    },
                    InputProps: {
                      sx: {
                        minWidth: '300px',
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#c4c4c4',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#c4c4c4',
                          borderWidth: 1,
                        },
                        borderRadius: '8px',
                      },
                      ...(false || filters.topics.length === 0 || filters.favorites
                        ? {
                            startAdornment: (
                              <InputAdornment position="start">
                                <Search sx={{ marginLeft: '12px' }} />
                              </InputAdornment>
                            ),
                          }
                        : undefined),
                    },
                  }}
                  autocompleteProps={{
                    disableClearable: true,
                    disableCloseOnSelect: true,
                    disabled: filters.favorites,
                    onBlur: () => {},
                    freeSolo: true,
                    clearIcon: null,
                    limitTags: 6,

                    onChange: (_, value) => {
                      handleUpdateTopics(value);
                    },
                    sx: {
                      minWidth: { xs: '100%', sm: '450px' },
                      '& .MuiAutocomplete-inputRoot': {
                        height: { xs: 'auto', md: '42px !important' },
                        padding: '0px !important',
                      },
                    },
                    renderTags: (topics, getTagProps) => {
                      const searchIcon = (
                        <Search
                          sx={{
                            color: 'rgba(0, 0, 0, 0.56)',
                          }}
                        />
                      );

                      return [
                        <div
                          key="search-icon"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: '12px',
                          }}
                        >
                          {searchIcon}
                        </div>,
                        ...(!topicModalOpen
                          ? topics.map((option, index) => (
                              <Chip
                                sx={{
                                  background: 'white',
                                  height: '30px',
                                  border: '1px solid rgba(255, 0, 122, 1)',
                                  padding: '0px 5px',
                                  '& .MuiChip-label': {
                                    color: 'rgba(255, 0, 122, 1)',
                                    background: 'white',
                                  },
                                  '& .MuiChip-deleteIcon': {
                                    color: 'rgba(255, 0, 122, 1)',
                                  },
                                }}
                                label={option.startsWith('#') ? option : `#${option}`}
                                {...getTagProps({ index })}
                                onDelete={() => {
                                  handleUpdateTopics(topics.filter((t) => t !== option));
                                }}
                                key={index}
                              />
                            ))
                          : []),
                      ];
                    },
                  }}
                />
              </FormContainer>
            </>
          )}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              sx: {
                overflow: 'visible',
                width: 250,
                boxShadow: '0px 11px 14px 0px rgba(0, 0, 0, 0.13)',
                borderRadius: '5px',
                marginTop: '1.5rem',
              },
            }}
          >
            <Typography
              fontWeight="bold"
              sx={{
                paddingLeft: '20px',
                color: 'rgba(194, 11, 99, 1)',
                marginY: '0.5rem',
              }}
            >
              {t('Content filter')}
            </Typography>
            {Object.values(InsightType).map((filter, i) => (
              <MenuItem key={i} onClick={() => toggleFilterOption(filter)}>
                <Checkbox
                  sx={{ marginRight: '5px' }}
                  checked={filters.insightTypes.includes(filter)}
                />
                <Typography fontWeight="bold">{t(filter)}</Typography>
              </MenuItem>
            ))}
          </Menu>
          <Button
            data-testid="favorite-button"
            sx={{
              border: filters.favorites ? '1px solid #FF007A' : '1px solid #BBBBBB',
              color: filters.favorites ? '#FF007A' : 'rgba(0, 0, 0, 0.56)',
              backgroundColor: filters.favorites ? 'rgba(255, 0, 122, 0.08)' : 'white',
              minWidth: '42px',
              width: '42px',
              height: '42px',
              padding: '0px important!',
            }}
            variant={filters.favorites ? 'contained' : 'outlined'}
            onClick={() => onFiltersChange({ ...filters, favorites: !filters.favorites })}
          >
            <Favorite data-testid="favorite-filled-icon" sx={{ width: '20px' }} />
          </Button>
        </Box>
        <TopicSelectorModal
          open={topicModalOpen}
          anchorElement={anchorModalElem!}
          onClose={() => setTopicModalOpen(false)}
          topics={filters.topics}
          onTopicsChange={handleUpdateTopics}
        />
      </Stack>
    </Card>
  );
};
